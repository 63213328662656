<template>
  <v-app>
    <div class="natural lighten-4 fill-height">
      <SingleAppBar :title="$t('settings.managePersonalData')" :backward="true" name="setting" />
      <v-main>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-card elevation="1">
                <v-card-text class="text-center">
                  <v-badge
                    bordered
                    color="natural"
                    overlap
                    bottom
                    offset-x="25"
                    offset-y="25"
                  >
                    <template slot="badge">
                      <v-icon small color="primary"> mdi-camera</v-icon>
                    </template>
                    <v-avatar size="104" @click="click">
                      <v-img
                        ref="imgSrc"
                        :src="
                          imageProfile || require('@/assets/whiteavatar.svg')
                        "
                        @error="($auth.idpPicture)? imageProfile = $auth.idpPicture : imageProfile = require('@/assets/whiteavatar.svg')"
                      >
                      </v-img>
                    </v-avatar>
                  </v-badge>
                  <v-file-input
                    :rules="rules"
                    accept="image/png, image/jpeg, image/bmp"
                    ref="file"
                    name="file"
                    @change="select"
                    style="display: none"
                  />
                  <!-- style="display: none" -->

                  <div class="text-left">
                    <label class="text-title-4 primary--text"
                      >{{ $t('patient.fullname') }}
                      <v-text-field
                        outlined
                        v-model="fullname"
                        :readonly="!isEditing"
                        @blur="updateFullname()"
                      >
                         <template v-slot:append-outer>
          <v-slide-x-reverse-transition
            mode="out-in"
          >
            <v-icon
              :key="`icon-${isEditing}`"
              :color="isEditing ? 'primary' : 'primary'"
              @click="isEditing = !isEditing"
              v-text="isEditing ? 'mdi-content-save' : 'mdi-circle-edit-outline'"
            ></v-icon>
          </v-slide-x-reverse-transition>
        </template>
                      </v-text-field>
                    </label>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="1">
                <v-card-title class="text-title-4 primary--text pb-1"
                  >{{ $t('settings.personalData') }}</v-card-title
                >
                <v-card-text>
                  <v-list class="pt-0">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon v-text="'mdi-account'"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-large"
                          v-text="(role.displayName)? role.displayName: role.fullname"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon  v-text="'mdi-phone'"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-large"
                          v-text="role.mobile"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="tenant" @click="$router.push({name: 'selecttenant'})">
                      <v-list-item-icon>
                        <v-icon
                          v-text="'mdi-home-group'"
                        ></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-large text-wrap"
                          v-text="tenant.tenantName"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="site" @click="$router.push({name: 'selecttenant'})">
                      <v-list-item-icon>
                        <v-icon
                          v-text="'mdi-home-city-outline'"
                        ></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-large text-wrap"
                          v-text="site.siteName"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                     <v-list-item @click="$router.push({name: 'qrscan'})">
                      <v-list-item-icon>
                        <v-icon
                          v-text="'mdi-qrcode-scan'"
                        ></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-body-large text-wrap"
                          v-text="'เพิ่มบริการใหม่'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="1">
                <v-card-title class="text-title-4 primary--text"
                  >{{ $t('settings.termandcondition') }}</v-card-title
                >
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-card outlined @click="$router.push({name: 'pn', params: { mode: 'view'}})">
                        <v-card-text
                          class="text-body-medium"
                        >
                          <v-row>
                            <v-col cols="10"
                              >นโยบายความเป็นตัวส่วนสำหรับสมาชิก</v-col
                            >
                            <v-col cols="1"
                              ><v-icon color="primary"
                                >mdi-arrow-right</v-icon
                              ></v-col
                            >
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-card outlined @click="$router.push({name: 'tou', params: { mode: 'view'}})">
                        <v-card-text
                          class="text-body-medium"
                        >
                          <v-row>
                            <v-col cols="10"
                              >ข้อกำหนดและเงื่อนไขในการให้บริการ</v-col
                            >
                            <v-col cols="1"
                              ><v-icon color="primary"
                                >mdi-arrow-right</v-icon
                              ></v-col
                            >
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12">
                      <v-card outlined @click="$router.push({name: 'cookies', params: { mode: 'view'}})">
                        <v-card-text
                          class="text-body-medium"
                        >
                          <v-row>
                            <v-col cols="10"
                              >นโยบายการใช้คุกกี้</v-col
                            >
                            <v-col cols="1"
                              ><v-icon color="primary"
                                >mdi-arrow-right</v-icon
                              ></v-col
                            >
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12">
                      <v-card outlined href="http://line.me/ti/p/~@vwell" target="_blank" rel="noopener">
                        <v-card-text
                          class="text-body-medium"
                        >
                          <v-row>
                            <v-col cols="10"
                              >การแจ้งประมวลผลข้อมูลส่วนบุคคล</v-col
                            >
                            <v-col cols="1"
                              ><v-icon color="primary"
                                >mdi-arrow-right</v-icon
                              ></v-col
                            >
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
         <v-dialog v-model="network">
      <NetworkCard @onClose="network = false" />
    </v-dialog>
      </v-main>
    </div>
  </v-app>
</template>

<script>
// @ is an alias to /src
import SingleAppBar from "@/components/common/SingleAppBar";
import { offlineimage } from "@/mixins/offlineimage";
import { getProfilePicture, uploadProfilePicture,user ,patchUser } from "@/api";
import NetworkCard from "@/components/common/NetworkCard";
import reduce from "image-blob-reduce";
import { cloneDeep } from 'lodash'
//import LangList from "@/components/lang/LangList"
export default {
  name: "Home",
  components: {
    SingleAppBar,
    NetworkCard
    // LangList
  },
  mixins: [offlineimage],
  data() {
    return {
      imageProfile: null,
      fullname: null,
      isEditing: false,
      role: null,
      loading: false,
      network: false,
      site: null,
      tenant: null,
      rules: [
        (value) => !value || value.size < 1000000 || "ขนาดรูปต้องต่ำกว่า 1 MB",
      ],
    };
  },
  methods: {
    updateFullname() {
      user(message=>{
        message.data.result.displayName = this.fullname
        patchUser({id:message.data.result.id,etag: message.data.result._etag},message.data.result,ret=>{
          console.log(ret.data)
             user(message=>{
               if(message.data.code ===1) {
                 this.$offlineStorage.set("role",message.data.result);
                 this.$auth.role = message.data.result
                 this.fetchData()
                 //.log(message.data.result)
               }
             })
        })
        console.log(message.data.result)
      })
    },
    click() {
      if (navigator.onLine) {
        if (!this.loading) {
          this.choose();
        }
      } else {
        this.network=true
      }
    },
    choose() {
      return this.$refs.file.$refs.input.click();
    },
    select(file) {
      if (file) {
            const rec = new reduce()
   rec
  .toBlob(file, { max: 1000 })
  .then(blob => { 
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = e => {
          // console.log(reader.result);
          this.loading = true
          this.imageProfile = e.target.result;
          let arrBase64 = e.target.result.split(',')
          let data = {
             fileType: arrBase64[0].match(/:(.*?);/)[1],
             fileEncode: arrBase64[1]
          }
          
          uploadProfilePicture(data,message=>{
            if(message) {
            // console.log(message)
            user(message=>{
              if(message.data.code === 1){
                  this.$offlineStorage.set("role",message.data.result);
                  this.role=message.data.result
                  getProfilePicture(
          {
            profilePicture: this.role.profilePicture,
          },
          (message) => {
            if (message.data.code === 1) {
              this.imageProfile = message.data.result;
              // this.saveImage(message.data.result, this.role.profilePicture);
            }
          },
          (error) => {
            console.log(error);
          }
        );
              }
            },error=>{
              console.log(error)
            })
            }
            this.loading = false
          },error=>{
            console.log(error)
            this.loading = false
          })

        }
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
   });
      }
    },
    fetchData() {
      this.loading = true;
      this.role = this.$offlineStorage.get("role");
      //console.log(this.role)
      const site = this.$offlineStorage.get("site");
      const tenant = this.$offlineStorage.get("tenant");
      (this.role.displayName)?this.fullname =  cloneDeep(this.role.displayName) : this.fullname =  cloneDeep(this.role.fullname)
      this.site = site;
      this.tenant = tenant;
      if (this.role.profilePicture) {
        //2 load profile
        getProfilePicture(
          {
            profilePicture: this.role.profilePicture,
          },
          (message) => {
            if (message.data.code === 1) {
              this.imageProfile = message.data.result;
              // this.saveImage(message.data.result, this.role.profilePicture);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }

      this.loading = false;
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.v-sheet.v-card {
  border-radius: 8px;
}

.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0;
  position: relative;
  text-decoration: none;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 15px;
}
</style>